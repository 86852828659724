.button {
  border: none;
  border-radius: 7px;
  background-color: var(--clr-dearBlue);
  font-size: 0.9rem;
  font-family: var(--ff-bold);
  color: var(--clr-white);
  width: 100%;
  text-align: center;
  padding: 5px 10px;
}

.button:focus {
  box-shadow: none;
}

.button:hover {
  opacity: 0.9;
}

.button:disabled {
  background-color: var(--clr-platinum) !important;
  color: var(--clr-white) !important;
  cursor: no-drop;
}
